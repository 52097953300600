<template>
  <div class="home-navigation-container" v-loading="loading">
    <el-row :gutter="60" style="margin: 0 0 40px 0;">
      <el-col :span="8" v-if="$canAndRulesPass(user, 'main_page.see_button_my_card')">
        <router-link :to="{ name: 'Cards', query: { my: 1 }}">
          <el-card>
            <el-row type="flex">
              <div class="card-icon"><i class="fas fa-address-card"></i></div>
              <div class="card-text one-line">
                <div>Мои карты целей</div>
              </div>
            </el-row>
          </el-card>
        </router-link>
      </el-col>
      <el-col :span="8" v-if="$canAndRulesPass(user, 'main_page.see_button_cards') && $canAndRulesPass(user, $permissions.CARD.LIST)">
        <router-link :to="{ name: 'Cards' }">
          <el-card>
            <el-row type="flex">
              <div class="card-icon"><i class="fas fa-list-ul"></i></div>
              <div class="card-text one-line">Все карты целей</div>
            </el-row>
          </el-card>
        </router-link>
      </el-col>
      <el-col :span="8" v-if="$canAndRulesPass(user, 'main_page.see_button_reports')">
        <router-link :to="{ name: 'Report' }">
          <el-card>
            <el-row type="flex">
              <div class="card-icon"><i class="fas fa-table"></i></div>
              <div class="card-text one-line">Отчеты</div>
            </el-row>
          </el-card>
        </router-link>
      </el-col>
    </el-row>


    <el-row :gutter="60" style="margin: 0 0 0 0;">
      <el-col :span="8" v-if="$canAndRulesPass(user, 'main_page.see_button_cards_my_turn')">
        <router-link :to="{ name: 'Cards', query: { my_turn: 1 }}">
          <el-card>
            <el-row type="flex">
              <div class="card-icon"><i class="fas fa-check"></i></div>
              <div class="card-text one-line">Карты на моем шаге</div>
              <div class="card-icon my-turn-counter" style="">{{ myTurnCounts }}</div>
            </el-row>
          </el-card>
        </router-link>

      </el-col>
<!--      <el-col :span="8">-->
<!--        <router-link :to="{ name: 'Matrix' }">-->
<!--          <el-card>-->
<!--            <el-row type="flex">-->
<!--              <div class="card-icon"><i class="fab fa-buromobelexperte"></i></div>-->
<!--              <div class="card-text one-line">Навигатор по целям подчинённых</div>-->
<!--            </el-row>-->
<!--          </el-card>-->
<!--        </router-link>-->
<!--      </el-col>-->
    </el-row>

  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "home-navigation",
  components: {},

  props: {},
  computed: {
    ...mapGetters(['user']),
  },

  data() {
    return {
      loading: false,
      myLastCard: null,
      myTurnCounts: 0,
    }
  },
  mounted() {
    this.loadNavigationData();
  },
  watch: {},
  methods: {
    loadNavigationData(){
        this.loading = true;
        this.$api.home.getNavigationData()
            .then(data => {
                this.myLastCard = data.my_last_card;
                this.myTurnCounts = data.my_turn_cards_count;
            })
            .finally(()=>{
                this.loading = false;
            })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

.home-navigation-container {

  a {
    text-decoration: none;
  }

  .el-card {
    .card-icon {
      padding: 0 0 0 20px;
      font-size: 30px;
      opacity: 0.5;
    }

    .card-text {
      width: 100%;
      text-align: center;
    }

    .card-text.one-line {
      padding-top: 10px;
      text-transform: uppercase;
    }
  }
}

.my-turn-counter{
  opacity: 1;
  padding-right:20px;
  color: $--color-primary;
}
</style>